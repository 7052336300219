/****************************
* Application Scss Variables
*****************************/
:export {
  aibsSelectHeight: 25px;
  colorBrandLight: #60a7e5;
  colorBlack: #000000;
  colorLight: #ffffff;
  colorNavyDark: #05070a;
  colorAliceBlue: #f0f8ff;
  colorGreyDark: #646364;
  colorGridGray: #eeeeee;
  colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
  translucent: rgba(255, 255, 255, 0.5);
  clear: rgba(255, 255, 255, 0);
  colorGreyExtraLight: #dee2e6; }

.header--top {
  overflow: hidden;
  position: relative; }

div.dendrogram-selection__container {
  overflow: auto;
  padding-left: 5px; }

.dendrogram__tooltip--bold {
  font-weight: 700; }

.dendrogram__selected-cluster-container__base {
  font-size: 12px;
  margin: 10px 5px; }

.dendrogram__selected-cluster-container__tooltip {
  font-size: 16px;
  margin: 5px; }

.dendrogram__selected-cluster-field__base {
  margin-left: 15px;
  cursor: default; }

.dendrogram-node-text__pivot-point {
  position: absolute;
  height: 0;
  width: 0; }

.dendrogram-node-text__label {
  position: absolute;
  font-size: 0.5rem;
  max-width: 60px;
  padding: 0 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: helvetica;
  bottom: 0; }
  .dendrogram-node-text__label.right {
    right: 0; }
  .dendrogram-node-text__label.left {
    left: 0; }
  .dendrogram-node-text__label.dimmed {
    opacity: 0.4; }
  .dendrogram-node-text__label.full-opacity {
    opacity: 1; }
