/****************************
* Application Scss Variables
*****************************/
:export {
  aibsSelectHeight: 25px;
  colorBrandLight: #60a7e5;
  colorBlack: #000000;
  colorLight: #ffffff;
  colorNavyDark: #05070a;
  colorAliceBlue: #f0f8ff;
  colorGreyDark: #646364;
  colorGridGray: #eeeeee;
  colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
  translucent: rgba(255, 255, 255, 0.5);
  clear: rgba(255, 255, 255, 0);
  colorGreyExtraLight: #dee2e6; }

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px; }

.header--visualization {
  color: #000000;
  background-color: #ffffff; }
