/****************************
* Application Scss Variables
*****************************/
:export {
  aibsSelectHeight: 25px;
  colorBrandLight: #60a7e5;
  colorBlack: #000000;
  colorLight: #ffffff;
  colorNavyDark: #05070a;
  colorAliceBlue: #f0f8ff;
  colorGreyDark: #646364;
  colorGridGray: #eeeeee;
  colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
  translucent: rgba(255, 255, 255, 0.5);
  clear: rgba(255, 255, 255, 0);
  colorGreyExtraLight: #dee2e6; }

.not-found__container {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap; }

.not-found__header {
  width: 400px;
  max-width: 100%;
  color: #05070a;
  font-size: 3.125rem; }

.not-found__sub-head {
  width: 400px;
  max-width: 100%;
  color: #05070a;
  font-size: 16px; }

.not-found__path {
  color: #a4a4a4; }

.not-found__links {
  width: 400px;
  max-width: 100%;
  font-size: 0.875rem;
  color: #000000;
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 20px; }
  .not-found__links > li {
    padding: 0;
    margin: 0; }

.not-found__tv-data {
  display: flex;
  flex-flow: row wrap; }

.not-found__tv-label {
  padding: 10px 4px 5px 0;
  width: 100%; }

.not-found__links a,
.not-found__links a:visited,
.not-found__links a:focus,
.not-found__links a:active {
  color: #60a7e5;
  text-decoration: none; }
