/****************************
* Application Scss Variables
*****************************/
:export {
  aibsSelectHeight: 25px;
  colorBrandLight: #60a7e5;
  colorBlack: #000000;
  colorLight: #ffffff;
  colorNavyDark: #05070a;
  colorAliceBlue: #f0f8ff;
  colorGreyDark: #646364;
  colorGridGray: #eeeeee;
  colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
  translucent: rgba(255, 255, 255, 0.5);
  clear: rgba(255, 255, 255, 0);
  colorGreyExtraLight: #dee2e6; }

.visualization__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  background-color: #ffffff;
  padding: 0 20px 0 10px;
  position: relative;
  z-index: 500;
  box-shadow: 0px 6px 12px -3px rgba(52, 52, 52, 0.61); }

.visualization-dropdown__container {
  display: flex;
  align-items: baseline; }

.visualization-dropdown__select--visualization {
  width: 200px; }

.visualization-dropdown__select--dataset {
  width: 300px;
  z-index: 600; }

.visualization-dropdown__label {
  font-size: 0.75rem;
  color: #777c84;
  padding-left: 10px; }
