/****************************
* Application Scss Variables
*****************************/
:export {
  aibsSelectHeight: 25px;
  colorBrandLight: #60a7e5;
  colorBlack: #000000;
  colorLight: #ffffff;
  colorNavyDark: #05070a;
  colorAliceBlue: #f0f8ff;
  colorGreyDark: #646364;
  colorGridGray: #eeeeee;
  colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
  translucent: rgba(255, 255, 255, 0.5);
  clear: rgba(255, 255, 255, 0);
  colorGreyExtraLight: #dee2e6; }

/****************************
* Application Scss Variables
*****************************/
:export {
  aibsSelectHeight: 25px;
  colorBrandLight: #60a7e5;
  colorBlack: #000000;
  colorLight: #ffffff;
  colorNavyDark: #05070a;
  colorAliceBlue: #f0f8ff;
  colorGreyDark: #646364;
  colorGridGray: #eeeeee;
  colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
  translucent: rgba(255, 255, 255, 0.5);
  clear: rgba(255, 255, 255, 0);
  colorGreyExtraLight: #dee2e6; }

.drawer__expanding-container, .drawer__expanding-container--open, .drawer__expanding-container--closed, .drawer__expanding-container, .drawer__expanding-container--open, .drawer__expanding-container--closed {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #05070a;
  transition: height ease-out 200ms;
  overflow: hidden; }

.drawer__expanding-container--closed {
  height: 32px; }

.drawer__header {
  height: 32px;
  display: flex;
  justify-content: space-evenly;
  width: 100%; }

.drawer__header-content, .drawer__header-content--left, .drawer__header-content--right {
  flex-grow: 1;
  height: 32px;
  padding: 8px 20px;
  font-size: 0.875rem; }
  .drawer__header-content a, .drawer__header-content--left a, .drawer__header-content--right a {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer; }
    .drawer__header-content a:hover, .drawer__header-content--left a:hover, .drawer__header-content--right a:hover {
      text-decoration: underline; }

.drawer__header-content--right {
  text-align: right; }

.drawer__header-toggle, .drawer__header-toggle--open, .drawer__header-toggle--closed {
  width: 80px;
  transition: background-color ease 300ms;
  border-top: 2px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .drawer__header-toggle i, .drawer__header-toggle--open i, .drawer__header-toggle--closed i {
    font-size: 20px;
    transition: transform 150ms ease-in-out; }
  .drawer__header-toggle:hover, .drawer__header-toggle--open:hover, .drawer__header-toggle--closed:hover {
    background-color: #616770;
    border-top: 2px solid #ffffff; }

.drawer__header-toggle--open > i {
  transform: rotate(0deg); }

.drawer__header-toggle--closed > i {
  transform: rotate(180deg); }

.drawer__content {
  background-color: #1c2532;
  overflow: hidden; }

:export {
  footerHeight: 32px; }

.application {
  display: flex;
  flex-direction: column;
  height: 100vh; }
  .application iframe {
    border: none; }

.application__footer {
  position: relative;
  overflow: visible;
  z-index: 800;
  height: 32px; }

.application__link-splitter {
  display: inline-block;
  width: 1.5px;
  height: 11px;
  background-color: #ffffff;
  margin: 0 6px; }

.tv-portal-header {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1c2532; }
  .tv-portal-header a {
    color: #ffffff;
    text-decoration: none; }

.tv-portal-header__return-to {
  text-decoration: none;
  font-size: 0.875rem;
  padding: 0 20px; }
  .tv-portal-header__return-to:hover {
    color: #a4a4a4; }

.tv-portal-header__icon {
  padding-right: 10px; }

.tv-portal-header__branding {
  display: flex;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none; }

.tv-portal-header__branding--ABM {
  letter-spacing: 0.0625rem;
  padding-right: 20px;
  border-right: solid #ffffff 1px;
  margin-right: 20px; }
