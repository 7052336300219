@import '../../styles/variables.scss';

$drawer-header-height: 32px;

.drawer__expanding-container,
.drawer__expanding-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $colorNavyDark;
    transition: height ease-out 200ms;
    overflow: hidden;
}

.drawer__expanding-container--open {
    @extend .drawer__expanding-container;
}

.drawer__expanding-container--closed {
    @extend .drawer__expanding-container;

    height: $drawer-header-height;
}

.drawer__header {
    height: $drawer-header-height;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.drawer__header-content {
    flex-grow: 1;
    height: $drawer-header-height;
    padding: 8px 20px;
    font-size: $fontSizeS;

    a {
        color: $colorLight;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

.drawer__header-content--left {
    @extend .drawer__header-content;
}

.drawer__header-content--right {
    @extend .drawer__header-content;

    text-align: right;
}

.drawer__header-toggle {
    width: 80px;
    transition: background-color ease 300ms;
    border-top: 2px solid transparent;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;


    i {
        font-size: 20px;
        transition: transform 150ms ease-in-out;
    }

    &:hover {
        background-color: $colorNavyLight;
        border-top: 2px solid $colorLight;
    }
}

.drawer__header-toggle--open {
    @extend .drawer__header-toggle;

    & > i {
        transform: rotate(0deg);
    }
}

.drawer__header-toggle--closed {
    @extend .drawer__header-toggle;

    & > i {
        transform: rotate(180deg);
    }
}

.drawer__content {
    background-color: $colorNavyMedium;
    overflow: hidden;
}

// export vars for use in Javascript
:export {
    footerHeight: $drawer-header-height;
}