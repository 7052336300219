@import url('https://use.fontawesome.com/releases/v5.12.1/css/all.css');
@import 'variables';

body,html {
    box-sizing: border-box;
    font-family: $font;
    font-size: $fontSizeM;
    padding: 0;
    margin: 0;
    background-color: $colorNavyMedium;
    color: $colorLight;
}
