/****************************
* Application Scss Variables
*****************************/
:export {
  aibsSelectHeight: 25px;
  colorBrandLight: #60a7e5;
  colorBlack: #000000;
  colorLight: #ffffff;
  colorNavyDark: #05070a;
  colorAliceBlue: #f0f8ff;
  colorGreyDark: #646364;
  colorGridGray: #eeeeee;
  colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
  translucent: rgba(255, 255, 255, 0.5);
  clear: rgba(255, 255, 255, 0);
  colorGreyExtraLight: #dee2e6; }

.color-by-container {
  display: flex;
  align-items: baseline; }

.color-by {
  min-width: 250px;
  display: flex;
  font-size: 0.875rem; }
  .color-by > div {
    flex-grow: 1; }

.gene-select, .gene-select--hide {
  box-sizing: border-box;
  min-width: 230px;
  display: flex;
  font-size: 0.875rem;
  transition: 300ms ease width;
  align-items: baseline; }
  .gene-select > div, .gene-select--hide > div {
    flex-grow: 1; }
  .gene-select .aibs-select__indicator, .gene-select--hide .aibs-select__indicator {
    height: 22px;
    padding: 0; }

.gene-select--hide {
  overflow: hidden;
  width: 0px;
  height: 0px; }

.gene-select-dropdown {
  min-width: 181px; }

.add-gene__modal-clicktrap {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 700; }

.add-gene {
  position: relative;
  font-family: "roboto", sans-serif;
  font-size: 0.875rem; }

.add-gene__gui-elements {
  display: flex;
  align-items: center;
  position: relative;
  color: #000000;
  font-size: inherit;
  z-index: 710; }

.add-gene__multi-label {
  padding-right: 15px; }

.add-gene__gene-search-container {
  padding-left: 15px;
  border-left: 1px solid #7e7d7e; }

.add-gene__text-label {
  display: block; }
  .add-gene__text-label:hover {
    cursor: pointer; }

.color-by-label {
  padding-top: 5px;
  padding-right: 5px; }
