/****************************
* Application Scss Variables
*****************************/
:export {
  aibsSelectHeight: 25px;
  colorBrandLight: #60a7e5;
  colorBlack: #000000;
  colorLight: #ffffff;
  colorNavyDark: #05070a;
  colorAliceBlue: #f0f8ff;
  colorGreyDark: #646364;
  colorGridGray: #eeeeee;
  colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
  translucent: rgba(255, 255, 255, 0.5);
  clear: rgba(255, 255, 255, 0);
  colorGreyExtraLight: #dee2e6; }

.label-menu {
  position: absolute;
  z-index: 900;
  background-color: #ffffff;
  padding: 5px 0;
  border: solid #a4a4a4 1px; }

.label-menu__option {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2px 10px;
  font-size: 0.875rem;
  font-weight: 500; }
  .label-menu__option:hover {
    background-color: #dee2e6; }
  .label-menu__option a {
    color: inherit;
    text-decoration: none; }
  .label-menu__option .row-label__action-icon-wrapper {
    margin-right: 5px;
    min-width: 20px; }
    .label-menu__option .row-label__action-icon-wrapper:hover {
      background-color: #dee2e6;
      color: #000000; }

.row-header__list {
  list-style: none;
  text-align: right;
  padding: 0 0 0 10px;
  margin: 0; }

.row-header__list-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #ffffff;
  font-size: 0.75rem;
  padding: 0 5px;
  color: #646364; }
  .row-header__list-item i {
    padding: 0 5px;
    cursor: pointer;
    font-size: 16px; }
  .row-header__list-item span.row-label__feature-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .row-header__list-item a {
    color: #000000; }

.row-header__category-header .row-header__action-icons {
  flex: 0 2 10%;
  margin-right: 0; }

.row-header__category-header.row-header__interactive {
  flex-direction: row-reverse;
  border-top: 1px solid #646364;
  margin-top: -1px; }

.row-header__category-header.row-header__static {
  border-bottom: 1px solid #000000;
  margin-bottom: 1px;
  color: #000000; }

.row-header__category-header .row-label__feature-text {
  margin-right: auto; }

.row-header__list-item__hovered {
  color: #000000; }

.row-header__interactive {
  cursor: pointer; }

.row-header__action-icons {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: center;
  flex: 0 2 65%;
  height: 20px;
  margin-right: auto;
  padding-right: 10px;
  overflow: hidden; }
  .row-header__action-icons i {
    font-size: 10px; }

.row-label__action-icon-wrapper {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  font-size: 10px;
  padding: 3px 2px 2px;
  height: 15px;
  align-items: center;
  justify-content: center; }
  .row-label__action-icon-wrapper:hover {
    background: #000000;
    color: #ffffff; }
  .row-label__action-icon-wrapper i {
    flex: 0 0 auto; }

.row-label__action-item-text {
  flex: 0 0 auto;
  font-size: 7px;
  overflow: visible;
  white-space: nowrap; }
