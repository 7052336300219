/****************************
* Application Scss Variables
*****************************/
:export {
  aibsSelectHeight: 25px;
  colorBrandLight: #60a7e5;
  colorBlack: #000000;
  colorLight: #ffffff;
  colorNavyDark: #05070a;
  colorAliceBlue: #f0f8ff;
  colorGreyDark: #646364;
  colorGridGray: #eeeeee;
  colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
  translucent: rgba(255, 255, 255, 0.5);
  clear: rgba(255, 255, 255, 0);
  colorGreyExtraLight: #dee2e6; }

div.aibs-select__control {
  font-family: "roboto", sans-serif;
  font-size: 0.875rem;
  min-height: 25px;
  height: 25px;
  background: transparent;
  background-color: none;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  cursor: pointer; }
  div.aibs-select__control .aibs-select__value-container {
    padding: 0 8px;
    height: 20px;
    position: static; }
  div.aibs-select__control .aibs-select__indicator-separator {
    display: none;
    position: static;
    height: 20px; }
  div.aibs-select__control .aibs-select__dropdown-indicator {
    padding: 0 8px; }
  div.aibs-select__control .aibs-select__single-value {
    color: inherit;
    font-family: inherit; }

div.aibs-select__menu {
  z-index: 600;
  border-radius: 0;
  margin-top: 3px;
  font-family: "roboto", sans-serif;
  font-size: 0.875rem;
  color: #05070a; }
  div.aibs-select__menu .aibs-select__menu-list {
    padding: 0; }
  div.aibs-select__menu .aibs-select__option--is-selected.aibs-select__option--is-focused,
  div.aibs-select__menu .aibs-select__option--is-selected {
    background-color: #05070a; }
  div.aibs-select__menu .aibs-select__option--is-focused {
    background-color: #dee2e6; }

div.aibs-select__control--is-focused,
div.aibs-select__control--menu-is-open {
  box-shadow: none; }
  div.aibs-select__control--is-focused:hover, div.aibs-select__control--is-focused :focus,
  div.aibs-select__control--menu-is-open:hover,
  div.aibs-select__control--menu-is-open :focus {
    border-color: transparent;
    box-shadow: none; }
