/****************************
* Application Scss Variables
*****************************/
:export {
  aibsSelectHeight: 25px;
  colorBrandLight: #60a7e5;
  colorBlack: #000000;
  colorLight: #ffffff;
  colorNavyDark: #05070a;
  colorAliceBlue: #f0f8ff;
  colorGreyDark: #646364;
  colorGridGray: #eeeeee;
  colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
  translucent: rgba(255, 255, 255, 0.5);
  clear: rgba(255, 255, 255, 0);
  colorGreyExtraLight: #dee2e6; }

.scalebar-wrapper__outer {
  display: flex;
  flex-direction: column;
  align-items: center; }

.scalebar-menu__modal-clicktrap {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 700; }

.scalebar-menu {
  position: absolute;
  background: #ffffff;
  box-shadow: 0 6px 12px -3px rgba(52, 52, 52, 0.61);
  padding: 5px;
  z-index: 705; }

.scalebar-menu__item {
  padding: 5px 5px 0;
  margin: 2px;
  border: 1px solid #ffffff; }
  .scalebar-menu__item:hover {
    background: #dee2e6; }
  .scalebar-menu__item.scalebar-menu__item__selected {
    border: 1px solid #777c84; }

.scalebar-wrapper__inner {
  padding-top: 5px;
  display: flex; }
  .scalebar-wrapper__inner .scalebar-chevron-wrapper {
    position: relative;
    cursor: pointer; }
    .scalebar-wrapper__inner .scalebar-chevron-wrapper i {
      position: absolute;
      padding-left: 15px;
      font-size: 0.75rem;
      color: #777c84; }

.scalebar {
  position: relative;
  cursor: pointer; }

.scalebar__canvas {
  position: absolute; }

.scalebar__svg {
  position: absolute;
  overflow: visible; }
