@import url(https://use.fontawesome.com/releases/v5.12.1/css/all.css);
/****************************
* Application Scss Variables
*****************************/

.drawer__expanding-container, .drawer__expanding-container--open, .drawer__expanding-container--closed, .drawer__expanding-container, .drawer__expanding-container--open, .drawer__expanding-container--closed {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #05070a;
  -webkit-transition: height ease-out 200ms;
  transition: height ease-out 200ms;
  overflow: hidden; }

.drawer__expanding-container--closed {
  height: 32px; }

.drawer__header {
  height: 32px;
  display: flex;
  justify-content: space-evenly;
  width: 100%; }

.drawer__header-content, .drawer__header-content--left, .drawer__header-content--right {
  flex-grow: 1;
  height: 32px;
  padding: 8px 20px;
  font-size: 0.875rem; }
  .drawer__header-content a, .drawer__header-content--left a, .drawer__header-content--right a {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer; }
    .drawer__header-content a:hover, .drawer__header-content--left a:hover, .drawer__header-content--right a:hover {
      text-decoration: underline; }

.drawer__header-content--right {
  text-align: right; }

.drawer__header-toggle, .drawer__header-toggle--open, .drawer__header-toggle--closed {
  width: 80px;
  -webkit-transition: background-color ease 300ms;
  transition: background-color ease 300ms;
  border-top: 2px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .drawer__header-toggle i, .drawer__header-toggle--open i, .drawer__header-toggle--closed i {
    font-size: 20px;
    -webkit-transition: -webkit-transform 150ms ease-in-out;
    transition: -webkit-transform 150ms ease-in-out;
    transition: transform 150ms ease-in-out;
    transition: transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out; }
  .drawer__header-toggle:hover, .drawer__header-toggle--open:hover, .drawer__header-toggle--closed:hover {
    background-color: #616770;
    border-top: 2px solid #ffffff; }

.drawer__header-toggle--open > i {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.drawer__header-toggle--closed > i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.drawer__content {
  background-color: #1c2532;
  overflow: hidden; }

/****************************
* Application Scss Variables
*****************************/

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px; }

.header--visualization {
  color: #000000;
  background-color: #ffffff; }

/****************************
* Application Scss Variables
*****************************/

div.aibs-select__control {
  font-family: "roboto", sans-serif;
  font-size: 0.875rem;
  min-height: 25px;
  height: 25px;
  background: transparent;
  background-color: none;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  cursor: pointer; }
  div.aibs-select__control .aibs-select__value-container {
    padding: 0 8px;
    height: 20px;
    position: static; }
  div.aibs-select__control .aibs-select__indicator-separator {
    display: none;
    position: static;
    height: 20px; }
  div.aibs-select__control .aibs-select__dropdown-indicator {
    padding: 0 8px; }
  div.aibs-select__control .aibs-select__single-value {
    color: inherit;
    font-family: inherit; }

div.aibs-select__menu {
  z-index: 600;
  border-radius: 0;
  margin-top: 3px;
  font-family: "roboto", sans-serif;
  font-size: 0.875rem;
  color: #05070a; }
  div.aibs-select__menu .aibs-select__menu-list {
    padding: 0; }
  div.aibs-select__menu .aibs-select__option--is-selected.aibs-select__option--is-focused,
  div.aibs-select__menu .aibs-select__option--is-selected {
    background-color: #05070a; }
  div.aibs-select__menu .aibs-select__option--is-focused {
    background-color: #dee2e6; }

div.aibs-select__control--is-focused,
div.aibs-select__control--menu-is-open {
  box-shadow: none; }
  div.aibs-select__control--is-focused:hover, div.aibs-select__control--is-focused :focus,
  div.aibs-select__control--menu-is-open:hover,
  div.aibs-select__control--menu-is-open :focus {
    border-color: transparent;
    box-shadow: none; }

/****************************
* Application Scss Variables
*****************************/

.gene-search {
  position: relative;
  font-family: "roboto", sans-serif;
  font-size: 0.875rem; }

.gene-search__gui-elements {
  display: flex;
  align-items: center;
  position: relative;
  color: #000000;
  font-size: inherit;
  z-index: 710; }

.gene-search__react-select {
  width: 175px; }

.gene-search__modal-clicktrap {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 700; }

.gene-search__text-label {
  display: block; }
  .gene-search__text-label:hover {
    cursor: pointer; }

.aibs-select__input {
  line-height: 3.5; }

.aibs-select__loading-indicator:first-child {
  margin-top: 8px;
  margin-bottom: -8px; }

.gene-search i {
  color: #a4a4a4;
  text-align: center;
  padding: 4px; }

.multi-gene-select__outer-container {
  display: flex;
  align-items: center;
  position: relative;
  color: #000000;
  font-size: inherit;
  z-index: 705; }

.multi-gene-select__inner-container {
  box-shadow: 0px 6px 12px -3px rgba(52, 52, 52, 0.61);
  position: absolute;
  width: 500px;
  background: #ffffff;
  right: 0;
  top: 17px;
  display: flex;
  flex-direction: column; }

.multi-gene-select__body {
  display: flex;
  font-size: 0.75rem;
  margin: 30px 30px 0; }

.multi-gene-select__text-area {
  flex: 1 0 auto;
  font-family: "roboto", sans-serif;
  font-size: 0.75rem;
  padding: 0 5px;
  margin: 0;
  height: 170px;
  overflow: auto;
  resize: none;
  outline: none;
  border: none;
  border-left: 1px solid #a4a4a4; }

.multi-gene-select__validation-report {
  overflow: auto;
  flex: 1 0 auto;
  text-align: right;
  height: 170px; }
  .multi-gene-select__validation-report p {
    padding: 0;
    margin: 0; }
  .multi-gene-select__validation-report i {
    padding-left: 5px; }
    .multi-gene-select__validation-report i.multi-gene-select__invalid-symbol {
      color: #ff3300; }
    .multi-gene-select__validation-report i.multi-gene-select__valid-symbol {
      color: #64a869; }

.multi-gene-select__footer-container {
  display: flex;
  align-items: center;
  margin: 10px 30px 20px;
  flex-flow: row-reverse;
  font-size: 0.75rem; }

.multi-gene-select__add-button {
  color: #444444;
  background: #F3F3F3;
  border: 1px #DADADA solid;
  padding: 5px 10px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 9pt;
  outline: none; }
  .multi-gene-select__add-button:disabled {
    background: #dee2e6;
    color: #a4a4a4; }
  .multi-gene-select__add-button:hover:enabled {
    border: 1px #C6C6C6 solid;
    box-shadow: 1px 1px 1px #EAEAEA;
    color: #333333;
    background: #F7F7F7;
    cursor: pointer; }
  .multi-gene-select__add-button:active:enabled {
    box-shadow: inset 1px 1px 1px #DFDFDF; }

/****************************
* Application Scss Variables
*****************************/

.color-by-container {
  display: flex;
  align-items: baseline; }

.color-by {
  min-width: 250px;
  display: flex;
  font-size: 0.875rem; }
  .color-by > div {
    flex-grow: 1; }

.gene-select, .gene-select--hide {
  box-sizing: border-box;
  min-width: 230px;
  display: flex;
  font-size: 0.875rem;
  -webkit-transition: 300ms ease width;
  transition: 300ms ease width;
  align-items: baseline; }
  .gene-select > div, .gene-select--hide > div {
    flex-grow: 1; }
  .gene-select .aibs-select__indicator, .gene-select--hide .aibs-select__indicator {
    height: 22px;
    padding: 0; }

.gene-select--hide {
  overflow: hidden;
  width: 0px;
  height: 0px; }

.gene-select-dropdown {
  min-width: 181px; }

.add-gene__modal-clicktrap {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 700; }

.add-gene {
  position: relative;
  font-family: "roboto", sans-serif;
  font-size: 0.875rem; }

.add-gene__gui-elements {
  display: flex;
  align-items: center;
  position: relative;
  color: #000000;
  font-size: inherit;
  z-index: 710; }

.add-gene__multi-label {
  padding-right: 15px; }

.add-gene__gene-search-container {
  padding-left: 15px;
  border-left: 1px solid #7e7d7e; }

.add-gene__text-label {
  display: block; }
  .add-gene__text-label:hover {
    cursor: pointer; }

.color-by-label {
  padding-top: 5px;
  padding-right: 5px; }

/****************************
* Application Scss Variables
*****************************/

.visualization__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  background-color: #ffffff;
  padding: 0 20px 0 10px;
  position: relative;
  z-index: 500;
  box-shadow: 0px 6px 12px -3px rgba(52, 52, 52, 0.61); }

.visualization-dropdown__container {
  display: flex;
  align-items: baseline; }

.visualization-dropdown__select--visualization {
  width: 200px; }

.visualization-dropdown__select--dataset {
  width: 300px;
  z-index: 600; }

.visualization-dropdown__label {
  font-size: 0.75rem;
  color: #777c84;
  padding-left: 10px; }

/****************************
* Application Scss Variables
*****************************/

.indicator-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 900;
  background-color: rgba(255, 255, 255, 0.6); }
  .indicator-container .label {
    width: 100%;
    text-align: center;
    line-height: 1.5;
    font: "roboto", sans-serif;
    font-size: 0.75rem; }

.loader {
  width: 128px; }

.loader-bar {
  position: relative;
  background-color: #ffffff;
  padding: 0 1px;
  display: flex;
  justify-content: space-evenly;
  border: 2px solid #05070a;
  width: 100%;
  background: linear-gradient(135deg, #60a7e5 10%, purple, yellow, #60a7e5 90%);
  background-size: 400% 100%;
  -webkit-animation: colorCycle 3s linear infinite;
          animation: colorCycle 3s linear infinite;
  margin: 0; }
  .loader-bar > li {
    list-style: none;
    width: 2px;
    height: 5px;
    margin: 0 2px;
    background-color: #05070a; }

@-webkit-keyframes colorCycle {
  0% {
    background-position: 400% 60%; }
  100% {
    background-position: 0% 50%; } }

@keyframes colorCycle {
  0% {
    background-position: 400% 60%; }
  100% {
    background-position: 0% 50%; } }

.error-container {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  z-index: 9999;
  color: #05070a; }

.error__header {
  font-size: 1.625rem;
  margin: 4px 0; }

.error__refresh {
  font-size: 0.875rem;
  margin: 4px 0 4px 12px;
  padding: 0; }

.error__refresh-countdown {
  font-family: monospace;
  color: #ff3300; }

.error__return {
  font-size: 0.875rem;
  margin: 4px 0 0 12px; }
  .error__return > a {
    text-decoration: none;
    color: #60a7e5; }

/****************************
* Application Scss Variables
*****************************/

.scatter-plot__plot {
  position: relative; }

.scatter-plot__info-box-header {
  font-size: 1.0625rem;
  margin: 0px 0px 15px 0px; }

.info-box-header__row {
  display: flex;
  justify-content: space-between; }
  .info-box-header__row :first-child {
    margin-right: 8px; }

.info-box-header--xxs {
  font-size: 0.5rem;
  font-weight: 100; }

.info-box-header--s {
  font-size: 0.875rem; }

.info-box__histogram {
  border-bottom: 1px solid #a4a4a4; }

.cell-expression-histogram__label-container {
  display: flex;
  height: 16px; }

.cell-expression-histogram__quantities {
  display: flex;
  margin-left: 8px; }

.cell-expression-histogram__average {
  margin-right: 5px; }

.cell-count-item__color {
  height: 25px;
  width: 5px;
  margin-right: 5px; }

.cell-expression-histogram__color {
  height: 8px;
  width: 8px;
  margin-right: 5px; }

.cell-count-item__count {
  width: 3em;
  text-align: right;
  padding-right: 15px; }

/****************************
* Application Scss Variables
*****************************/

.scalebar-wrapper__outer {
  display: flex;
  flex-direction: column;
  align-items: center; }

.scalebar-menu__modal-clicktrap {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 700; }

.scalebar-menu {
  position: absolute;
  background: #ffffff;
  box-shadow: 0 6px 12px -3px rgba(52, 52, 52, 0.61);
  padding: 5px;
  z-index: 705; }

.scalebar-menu__item {
  padding: 5px 5px 0;
  margin: 2px;
  border: 1px solid #ffffff; }
  .scalebar-menu__item:hover {
    background: #dee2e6; }
  .scalebar-menu__item.scalebar-menu__item__selected {
    border: 1px solid #777c84; }

.scalebar-wrapper__inner {
  padding-top: 5px;
  display: flex; }
  .scalebar-wrapper__inner .scalebar-chevron-wrapper {
    position: relative;
    cursor: pointer; }
    .scalebar-wrapper__inner .scalebar-chevron-wrapper i {
      position: absolute;
      padding-left: 15px;
      font-size: 0.75rem;
      color: #777c84; }

.scalebar {
  position: relative;
  cursor: pointer; }

.scalebar__canvas {
  position: absolute; }

.scalebar__svg {
  position: absolute;
  overflow: visible; }

/****************************
* Application Scss Variables
*****************************/

.scatter-plot__scalebar {
  position: absolute;
  bottom: 40px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  z-index: 600; }

.scatter-plot__info-box {
  padding: 15px;
  position: absolute;
  overflow: auto;
  margin: 20px 15px;
  border: solid #dee2e6 1px;
  background-color: #ffffff;
  overflow-y: auto;
  box-shadow: 0 6px 12px -3px rgba(52, 52, 52, 0.61);
  z-index: 250; }

.scatter-plot__info-box-item--cell-type {
  font-size: 0.875rem;
  margin: 10px 0px;
  display: flex;
  align-items: baseline; }

.scatter-plot__info-box-item--gene-expression {
  font-size: 0.5rem;
  margin: 10px 0px;
  display: flex;
  align-items: baseline; }

/****************************
* Application Scss Variables
*****************************/

.heatmap__table {
  margin-top: 10px;
  display: flex;
  overflow: hidden;
  position: relative;
  outline: none; }

.header--top {
  overflow: hidden; }

.outer-translate-wrapper__windowed {
  margin: 0;
  padding: 0; }

.follower-wrapper {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden; }

.inner-translate-wrapper__total {
  overflow: hidden; }

.scrollbar-thumb__div {
  background-color: rgba(0, 0, 0, 0.8); }

/****************************
* Application Scss Variables
*****************************/

/****************************
* Application Scss Variables
*****************************/

.hide-show__container__base {
  background: #ffffff;
  border: 1px solid #a4a4a4;
  box-shadow: 0px 6px 12px -3px rgba(52, 52, 52, 0.61);
  display: inline-block;
  left: 15px;
  margin: 20px 10px;
  min-width: 175px;
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 230; }

.hide-show__header__base {
  padding: 10px;
  font-size: 10px;
  cursor: pointer; }
  .hide-show__header__base i {
    margin-right: 10px; }

/****************************
* Application Scss Variables
*****************************/

.header--top {
  overflow: hidden;
  position: relative; }

div.dendrogram-selection__container {
  overflow: auto;
  padding-left: 5px; }

.dendrogram__tooltip--bold {
  font-weight: 700; }

.dendrogram__selected-cluster-container__base {
  font-size: 12px;
  margin: 10px 5px; }

.dendrogram__selected-cluster-container__tooltip {
  font-size: 16px;
  margin: 5px; }

.dendrogram__selected-cluster-field__base {
  margin-left: 15px;
  cursor: default; }

.dendrogram-node-text__pivot-point {
  position: absolute;
  height: 0;
  width: 0; }

.dendrogram-node-text__label {
  position: absolute;
  font-size: 0.5rem;
  max-width: 60px;
  padding: 0 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: helvetica;
  bottom: 0; }
  .dendrogram-node-text__label.right {
    right: 0; }
  .dendrogram-node-text__label.left {
    left: 0; }
  .dendrogram-node-text__label.dimmed {
    opacity: 0.4; }
  .dendrogram-node-text__label.full-opacity {
    opacity: 1; }

/****************************
* Application Scss Variables
*****************************/

.label-menu {
  position: absolute;
  z-index: 900;
  background-color: #ffffff;
  padding: 5px 0;
  border: solid #a4a4a4 1px; }

.label-menu__option {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2px 10px;
  font-size: 0.875rem;
  font-weight: 500; }
  .label-menu__option:hover {
    background-color: #dee2e6; }
  .label-menu__option a {
    color: inherit;
    text-decoration: none; }
  .label-menu__option .row-label__action-icon-wrapper {
    margin-right: 5px;
    min-width: 20px; }
    .label-menu__option .row-label__action-icon-wrapper:hover {
      background-color: #dee2e6;
      color: #000000; }

.row-header__list {
  list-style: none;
  text-align: right;
  padding: 0 0 0 10px;
  margin: 0; }

.row-header__list-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #ffffff;
  font-size: 0.75rem;
  padding: 0 5px;
  color: #646364; }
  .row-header__list-item i {
    padding: 0 5px;
    cursor: pointer;
    font-size: 16px; }
  .row-header__list-item span.row-label__feature-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .row-header__list-item a {
    color: #000000; }

.row-header__category-header .row-header__action-icons {
  flex: 0 2 10%;
  margin-right: 0; }

.row-header__category-header.row-header__interactive {
  flex-direction: row-reverse;
  border-top: 1px solid #646364;
  margin-top: -1px; }

.row-header__category-header.row-header__static {
  border-bottom: 1px solid #000000;
  margin-bottom: 1px;
  color: #000000; }

.row-header__category-header .row-label__feature-text {
  margin-right: auto; }

.row-header__list-item__hovered {
  color: #000000; }

.row-header__interactive {
  cursor: pointer; }

.row-header__action-icons {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: center;
  flex: 0 2 65%;
  height: 20px;
  margin-right: auto;
  padding-right: 10px;
  overflow: hidden; }
  .row-header__action-icons i {
    font-size: 10px; }

.row-label__action-icon-wrapper {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  font-size: 10px;
  padding: 3px 2px 2px;
  height: 15px;
  align-items: center;
  justify-content: center; }
  .row-label__action-icon-wrapper:hover {
    background: #000000;
    color: #ffffff; }
  .row-label__action-icon-wrapper i {
    flex: 0 0 auto; }

.row-label__action-item-text {
  flex: 0 0 auto;
  font-size: 7px;
  overflow: visible;
  white-space: nowrap; }

/****************************
* Application Scss Variables
*****************************/

.heatmap__tooltip {
  padding: 5px; }

.heatmap__tooltip--bold {
  font-weight: 700; }

/****************************
* Application Scss Variables
*****************************/

.sampling-strategy__table {
  margin-top: 10px;
  display: flex;
  overflow: hidden;
  position: relative;
  outline: none; }

.header--top {
  overflow: hidden; }

.labels--left {
  overflow: hidden;
  position: relative;
  cursor: default; }

/****************************
* Application Scss Variables
*****************************/

.sampling-strategy__tooltip {
  padding: 5px; }

.sampling-strategy__tooltip--bold {
  font-weight: 700; }

.sampling-strategy__tooltip--spacer {
  line-height: 1rem; }

/****************************
* Application Scss Variables
*****************************/

.abe-tooltip, .abe-tooltip--closed {
  position: absolute;
  z-index: 600;
  pointer-events: none;
  box-shadow: 0px 10px 13px 1px rgba(52, 52, 52, 0.45);
  border: 1px solid #a4a4a4;
  padding: 5px;
  background: rgba(255, 255, 255, 0.95); }

.abe-tooltip--closed {
  display: none; }

/****************************
* Application Scss Variables
*****************************/

.error-report {
  position: absolute;
  z-index: 1000; }

.error-report__article {
  position: relative;
  border-width: 0;
  background: #ffffff;
  padding: 5px;
  margin: 10px;
  overflow: hidden;
  font-size: 0.75rem;
  box-shadow: 0 20px 32px -8px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.25);
  max-width: 350px; }
  .error-report__article i {
    top: 0;
    margin: 10px 15px;
    position: absolute; }
  .error-report__article p {
    margin: 10px 35px; }

.dismiss-error {
  right: 0;
  cursor: pointer; }

i.error-icon {
  margin-top: 16px;
  left: 0;
  color: #ff3300; }

.error-report__article-friendly-message {
  font-weight: 600; }

.error-report__article-original-message {
  border-top: 1px solid #a4a4a4;
  padding-top: 5px;
  font-style: italic; }

/****************************
* Application Scss Variables
*****************************/

.visualization {
  background-color: #ffffff;
  color: #000000;
  flex-grow: 1;
  overflow: hidden; }

/****************************
* Application Scss Variables
*****************************/

/****************************
* Application Scss Variables
*****************************/

.drawer__expanding-container, .drawer__expanding-container--open, .drawer__expanding-container--closed, .drawer__expanding-container, .drawer__expanding-container--open, .drawer__expanding-container--closed {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #05070a;
  -webkit-transition: height ease-out 200ms;
  transition: height ease-out 200ms;
  overflow: hidden; }

.drawer__expanding-container--closed {
  height: 32px; }

.drawer__header {
  height: 32px;
  display: flex;
  justify-content: space-evenly;
  width: 100%; }

.drawer__header-content, .drawer__header-content--left, .drawer__header-content--right {
  flex-grow: 1;
  height: 32px;
  padding: 8px 20px;
  font-size: 0.875rem; }
  .drawer__header-content a, .drawer__header-content--left a, .drawer__header-content--right a {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer; }
    .drawer__header-content a:hover, .drawer__header-content--left a:hover, .drawer__header-content--right a:hover {
      text-decoration: underline; }

.drawer__header-content--right {
  text-align: right; }

.drawer__header-toggle, .drawer__header-toggle--open, .drawer__header-toggle--closed {
  width: 80px;
  -webkit-transition: background-color ease 300ms;
  transition: background-color ease 300ms;
  border-top: 2px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .drawer__header-toggle i, .drawer__header-toggle--open i, .drawer__header-toggle--closed i {
    font-size: 20px;
    -webkit-transition: -webkit-transform 150ms ease-in-out;
    transition: -webkit-transform 150ms ease-in-out;
    transition: transform 150ms ease-in-out;
    transition: transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out; }
  .drawer__header-toggle:hover, .drawer__header-toggle--open:hover, .drawer__header-toggle--closed:hover {
    background-color: #616770;
    border-top: 2px solid #ffffff; }

.drawer__header-toggle--open > i {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.drawer__header-toggle--closed > i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.drawer__content {
  background-color: #1c2532;
  overflow: hidden; }

.application {
  display: flex;
  flex-direction: column;
  height: 100vh; }
  .application iframe {
    border: none; }

.application__footer {
  position: relative;
  overflow: visible;
  z-index: 800;
  height: 32px; }

.application__link-splitter {
  display: inline-block;
  width: 1.5px;
  height: 11px;
  background-color: #ffffff;
  margin: 0 6px; }

.tv-portal-header {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1c2532; }
  .tv-portal-header a {
    color: #ffffff;
    text-decoration: none; }

.tv-portal-header__return-to {
  text-decoration: none;
  font-size: 0.875rem;
  padding: 0 20px; }
  .tv-portal-header__return-to:hover {
    color: #a4a4a4; }

.tv-portal-header__icon {
  padding-right: 10px; }

.tv-portal-header__branding {
  display: flex;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none; }

.tv-portal-header__branding--ABM {
  letter-spacing: 0.0625rem;
  padding-right: 20px;
  border-right: solid #ffffff 1px;
  margin-right: 20px; }

/****************************
* Application Scss Variables
*****************************/

.not-found__container {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap; }

.not-found__header {
  width: 400px;
  max-width: 100%;
  color: #05070a;
  font-size: 3.125rem; }

.not-found__sub-head {
  width: 400px;
  max-width: 100%;
  color: #05070a;
  font-size: 16px; }

.not-found__path {
  color: #a4a4a4; }

.not-found__links {
  width: 400px;
  max-width: 100%;
  font-size: 0.875rem;
  color: #000000;
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 20px; }
  .not-found__links > li {
    padding: 0;
    margin: 0; }

.not-found__tv-data {
  display: flex;
  flex-flow: row wrap; }

.not-found__tv-label {
  padding: 10px 4px 5px 0;
  width: 100%; }

.not-found__links a,
.not-found__links a:visited,
.not-found__links a:focus,
.not-found__links a:active {
  color: #60a7e5;
  text-decoration: none; }

/****************************
* Application Scss Variables
*****************************/

body, html {
  box-sizing: border-box;
  font-family: "roboto", sans-serif;
  font-size: 16px;
  padding: 0;
  margin: 0;
  background-color: #1c2532;
  color: #ffffff; }

