/****************************
* Application Scss Variables
*****************************/
:export {
  aibsSelectHeight: 25px;
  colorBrandLight: #60a7e5;
  colorBlack: #000000;
  colorLight: #ffffff;
  colorNavyDark: #05070a;
  colorAliceBlue: #f0f8ff;
  colorGreyDark: #646364;
  colorGridGray: #eeeeee;
  colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
  translucent: rgba(255, 255, 255, 0.5);
  clear: rgba(255, 255, 255, 0);
  colorGreyExtraLight: #dee2e6; }

.abe-tooltip, .abe-tooltip--closed {
  position: absolute;
  z-index: 600;
  pointer-events: none;
  box-shadow: 0px 10px 13px 1px rgba(52, 52, 52, 0.45);
  border: 1px solid #a4a4a4;
  padding: 5px;
  background: rgba(255, 255, 255, 0.95); }

.abe-tooltip--closed {
  display: none; }
