/****************************
* Application Scss Variables
*****************************/
:export {
  aibsSelectHeight: 25px;
  colorBrandLight: #60a7e5;
  colorBlack: #000000;
  colorLight: #ffffff;
  colorNavyDark: #05070a;
  colorAliceBlue: #f0f8ff;
  colorGreyDark: #646364;
  colorGridGray: #eeeeee;
  colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
  translucent: rgba(255, 255, 255, 0.5);
  clear: rgba(255, 255, 255, 0);
  colorGreyExtraLight: #dee2e6; }

.indicator-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 900;
  background-color: rgba(255, 255, 255, 0.6); }
  .indicator-container .label {
    width: 100%;
    text-align: center;
    line-height: 1.5;
    font: "roboto", sans-serif;
    font-size: 0.75rem; }

.loader {
  width: 128px; }

.loader-bar {
  position: relative;
  background-color: #ffffff;
  padding: 0 1px;
  display: flex;
  justify-content: space-evenly;
  border: 2px solid #05070a;
  width: 100%;
  background: linear-gradient(135deg, #60a7e5 10%, purple, yellow, #60a7e5 90%);
  background-size: 400% 100%;
  animation: colorCycle 3s linear infinite;
  margin: 0; }
  .loader-bar > li {
    list-style: none;
    width: 2px;
    height: 5px;
    margin: 0 2px;
    background-color: #05070a; }

@keyframes colorCycle {
  0% {
    background-position: 400% 60%; }
  100% {
    background-position: 0% 50%; } }

.error-container {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  z-index: 9999;
  color: #05070a; }

.error__header {
  font-size: 1.625rem;
  margin: 4px 0; }

.error__refresh {
  font-size: 0.875rem;
  margin: 4px 0 4px 12px;
  padding: 0; }

.error__refresh-countdown {
  font-family: monospace;
  color: #ff3300; }

.error__return {
  font-size: 0.875rem;
  margin: 4px 0 0 12px; }
  .error__return > a {
    text-decoration: none;
    color: #60a7e5; }
