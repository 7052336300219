
/****************************
* Application Scss Variables
*****************************/

// Primary brand colors
$colorBrand: #003056;
$colorBrandLight: #60a7e5;
$colorBrandGrey: #7e7d7e;
$colorBrandYellow: #ffe800;
$colorBrandGreen: #64a869;

// Navy Blues
$colorNavyLight: #616770;
$colorNavyMedium: #1c2532;
$colorNavyDark: #05070a;

// Grays
$colorGreyExtraLight: #dee2e6;
$colorGreyLight: #a4a4a4;
// Body font color
$colorGreyMedium: #777c84;
$colorGreyDark: #646364;
$colorBlack: #000000;
$colorLight: #ffffff;

// Chart colors:
$colorAliceBlue: #f0f8ff;
$colorGridGray: rgba(238, 238, 238, 1);
$colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
$translucent: rgba(255, 255, 255, 0.5);
$clear: rgba(255, 255, 255, 0);

// Errors
$colorRed: #ff3300;

// Default Font Sizes
// 50px
$fontSizeXXXL: 3.125rem;
// 40px
$fontSizeXXL: 2.5rem;
// 26px
$fontSizeXL: 1.625rem;
// 17px
$fontSizeL: 1.0625rem;
// Baseline font size / 16px === 1rem
// do not convert to rems
$fontSizeM: 16px;
// 14px
$fontSizeS: 0.875rem;
// 12px
$fontSizeXS: 0.75rem;
// 8px
$fontSizeXXS: 0.5rem;


// Default font weights
$fontRegular: 500;
$fontMedium: 600;
$fontBold: 700;

// Used by both Brain-map and BICCN
$font: 'roboto', sans-serif;
$fontIcon: 'Font Awesome 5 Free';

// react-select vars
$aibsSelectHeight: 25px;

$headerHeight: 32px;

// map of app-wide z-index values.
// how to use: z-index: zindex(scatter-plot-status-layer);
$zindex: (
        error-article               : 1000,
        label-menu                  : 900,
        footer-content              : 800,
        gene-search                 : 710,
        multi-gene-add              : 705,
        scalebar-menu               : 705,
        gene-search-clicktrap       : 700,
        clicktrap                   : 700,
        abe-header                  : 600,
        scatter-plot-scalebar               : 600,
        visualization-header        : 500,
        scatter-plot-status-layer           : 250,
        visualization-interface     : 230,
        visualization               : 200
);

@function zindex($key) {
        @return map-get($zindex, $key);
}

// export vars for use in Javascript
:export {
        aibsSelectHeight: $aibsSelectHeight;
        colorBrandLight: $colorBrandLight;
        colorBlack: $colorBlack;
        colorLight: $colorLight;
        colorNavyDark: $colorNavyDark;
        colorAliceBlue: $colorAliceBlue;
        colorGreyDark: $colorGreyDark;
        colorGridGray: $colorGridGray;
        colorGridGrayHighlight: $colorGridGrayHighlight;
        translucent: $translucent;
        clear: $clear;
        colorGreyExtraLight: $colorGreyExtraLight;
}
