.outer-translate-wrapper__windowed {
    margin: 0;
    padding: 0;
}

.follower-wrapper {
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
}

.inner-translate-wrapper__total {
    overflow: hidden;
}

.scrollbar-thumb__div {
    background-color: rgba(0, 0, 0, 0.8)
}
