/****************************
* Application Scss Variables
*****************************/
:export {
  aibsSelectHeight: 25px;
  colorBrandLight: #60a7e5;
  colorBlack: #000000;
  colorLight: #ffffff;
  colorNavyDark: #05070a;
  colorAliceBlue: #f0f8ff;
  colorGreyDark: #646364;
  colorGridGray: #eeeeee;
  colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
  translucent: rgba(255, 255, 255, 0.5);
  clear: rgba(255, 255, 255, 0);
  colorGreyExtraLight: #dee2e6; }

.hide-show__container__base {
  background: #ffffff;
  border: 1px solid #a4a4a4;
  box-shadow: 0px 6px 12px -3px rgba(52, 52, 52, 0.61);
  display: inline-block;
  left: 15px;
  margin: 20px 10px;
  min-width: 175px;
  position: absolute;
  user-select: none;
  z-index: 230; }

.hide-show__header__base {
  padding: 10px;
  font-size: 10px;
  cursor: pointer; }
  .hide-show__header__base i {
    margin-right: 10px; }
