/****************************
* Application Scss Variables
*****************************/
:export {
  aibsSelectHeight: 25px;
  colorBrandLight: #60a7e5;
  colorBlack: #000000;
  colorLight: #ffffff;
  colorNavyDark: #05070a;
  colorAliceBlue: #f0f8ff;
  colorGreyDark: #646364;
  colorGridGray: #eeeeee;
  colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
  translucent: rgba(255, 255, 255, 0.5);
  clear: rgba(255, 255, 255, 0);
  colorGreyExtraLight: #dee2e6; }

.scatter-plot__scalebar {
  position: absolute;
  bottom: 40px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  z-index: 600; }

.scatter-plot__info-box {
  padding: 15px;
  position: absolute;
  overflow: auto;
  margin: 20px 15px;
  border: solid #dee2e6 1px;
  background-color: #ffffff;
  overflow-y: auto;
  box-shadow: 0 6px 12px -3px rgba(52, 52, 52, 0.61);
  z-index: 250; }

.scatter-plot__info-box-item--cell-type {
  font-size: 0.875rem;
  margin: 10px 0px;
  display: flex;
  align-items: baseline; }

.scatter-plot__info-box-item--gene-expression {
  font-size: 0.5rem;
  margin: 10px 0px;
  display: flex;
  align-items: baseline; }
