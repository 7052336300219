/****************************
* Application Scss Variables
*****************************/
:export {
  aibsSelectHeight: 25px;
  colorBrandLight: #60a7e5;
  colorBlack: #000000;
  colorLight: #ffffff;
  colorNavyDark: #05070a;
  colorAliceBlue: #f0f8ff;
  colorGreyDark: #646364;
  colorGridGray: #eeeeee;
  colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
  translucent: rgba(255, 255, 255, 0.5);
  clear: rgba(255, 255, 255, 0);
  colorGreyExtraLight: #dee2e6; }

.error-report {
  position: absolute;
  z-index: 1000; }

.error-report__article {
  position: relative;
  border-width: 0;
  background: #ffffff;
  padding: 5px;
  margin: 10px;
  overflow: hidden;
  font-size: 0.75rem;
  box-shadow: 0 20px 32px -8px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.25);
  max-width: 350px; }
  .error-report__article i {
    top: 0;
    margin: 10px 15px;
    position: absolute; }
  .error-report__article p {
    margin: 10px 35px; }

.dismiss-error {
  right: 0;
  cursor: pointer; }

i.error-icon {
  margin-top: 16px;
  left: 0;
  color: #ff3300; }

.error-report__article-friendly-message {
  font-weight: 600; }

.error-report__article-original-message {
  border-top: 1px solid #a4a4a4;
  padding-top: 5px;
  font-style: italic; }
