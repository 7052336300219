/****************************
* Application Scss Variables
*****************************/
:export {
  aibsSelectHeight: 25px;
  colorBrandLight: #60a7e5;
  colorBlack: #000000;
  colorLight: #ffffff;
  colorNavyDark: #05070a;
  colorAliceBlue: #f0f8ff;
  colorGreyDark: #646364;
  colorGridGray: #eeeeee;
  colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
  translucent: rgba(255, 255, 255, 0.5);
  clear: rgba(255, 255, 255, 0);
  colorGreyExtraLight: #dee2e6; }

.scatter-plot__plot {
  position: relative; }

.scatter-plot__info-box-header {
  font-size: 1.0625rem;
  margin: 0px 0px 15px 0px; }

.info-box-header__row {
  display: flex;
  justify-content: space-between; }
  .info-box-header__row :first-child {
    margin-right: 8px; }

.info-box-header--xxs {
  font-size: 0.5rem;
  font-weight: 100; }

.info-box-header--s {
  font-size: 0.875rem; }

.info-box__histogram {
  border-bottom: 1px solid #a4a4a4; }

.cell-expression-histogram__label-container {
  display: flex;
  height: 16px; }

.cell-expression-histogram__quantities {
  display: flex;
  margin-left: 8px; }

.cell-expression-histogram__average {
  margin-right: 5px; }

.cell-count-item__color {
  height: 25px;
  width: 5px;
  margin-right: 5px; }

.cell-expression-histogram__color {
  height: 8px;
  width: 8px;
  margin-right: 5px; }

.cell-count-item__count {
  width: 3em;
  text-align: right;
  padding-right: 15px; }
