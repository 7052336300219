/****************************
* Application Scss Variables
*****************************/
:export {
  aibsSelectHeight: 25px;
  colorBrandLight: #60a7e5;
  colorBlack: #000000;
  colorLight: #ffffff;
  colorNavyDark: #05070a;
  colorAliceBlue: #f0f8ff;
  colorGreyDark: #646364;
  colorGridGray: #eeeeee;
  colorGridGrayHighlight: rgba(5, 7, 10, 0.15);
  translucent: rgba(255, 255, 255, 0.5);
  clear: rgba(255, 255, 255, 0);
  colorGreyExtraLight: #dee2e6; }

.gene-search {
  position: relative;
  font-family: "roboto", sans-serif;
  font-size: 0.875rem; }

.gene-search__gui-elements {
  display: flex;
  align-items: center;
  position: relative;
  color: #000000;
  font-size: inherit;
  z-index: 710; }

.gene-search__react-select {
  width: 175px; }

.gene-search__modal-clicktrap {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 700; }

.gene-search__text-label {
  display: block; }
  .gene-search__text-label:hover {
    cursor: pointer; }

.aibs-select__input {
  line-height: 3.5; }

.aibs-select__loading-indicator:first-child {
  margin-top: 8px;
  margin-bottom: -8px; }

.gene-search i {
  color: #a4a4a4;
  text-align: center;
  padding: 4px; }

.multi-gene-select__outer-container {
  display: flex;
  align-items: center;
  position: relative;
  color: #000000;
  font-size: inherit;
  z-index: 705; }

.multi-gene-select__inner-container {
  box-shadow: 0px 6px 12px -3px rgba(52, 52, 52, 0.61);
  position: absolute;
  width: 500px;
  background: #ffffff;
  right: 0;
  top: 17px;
  display: flex;
  flex-direction: column; }

.multi-gene-select__body {
  display: flex;
  font-size: 0.75rem;
  margin: 30px 30px 0; }

.multi-gene-select__text-area {
  flex: 1 0 auto;
  font-family: "roboto", sans-serif;
  font-size: 0.75rem;
  padding: 0 5px;
  margin: 0;
  height: 170px;
  overflow: auto;
  resize: none;
  outline: none;
  border: none;
  border-left: 1px solid #a4a4a4; }

.multi-gene-select__validation-report {
  overflow: auto;
  flex: 1 0 auto;
  text-align: right;
  height: 170px; }
  .multi-gene-select__validation-report p {
    padding: 0;
    margin: 0; }
  .multi-gene-select__validation-report i {
    padding-left: 5px; }
    .multi-gene-select__validation-report i.multi-gene-select__invalid-symbol {
      color: #ff3300; }
    .multi-gene-select__validation-report i.multi-gene-select__valid-symbol {
      color: #64a869; }

.multi-gene-select__footer-container {
  display: flex;
  align-items: center;
  margin: 10px 30px 20px;
  flex-flow: row-reverse;
  font-size: 0.75rem; }

.multi-gene-select__add-button {
  color: #444444;
  background: #F3F3F3;
  border: 1px #DADADA solid;
  padding: 5px 10px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 9pt;
  outline: none; }
  .multi-gene-select__add-button:disabled {
    background: #dee2e6;
    color: #a4a4a4; }
  .multi-gene-select__add-button:hover:enabled {
    border: 1px #C6C6C6 solid;
    box-shadow: 1px 1px 1px #EAEAEA;
    color: #333333;
    background: #F7F7F7;
    cursor: pointer; }
  .multi-gene-select__add-button:active:enabled {
    box-shadow: inset 1px 1px 1px #DFDFDF; }
